import SmithaPng from "../../../Utils/images/Testimonial/smitha.png";
import AyeshaPng from "../../../Utils/images/Testimonial/ayesha.png";
import YogeshPng from "../../../Utils/images/Testimonial/yogesh.png";
import MamthaPng from "../../../Utils/images/Testimonial/mamtha.png";
import MohanaPng from "../../../Utils/images/Testimonial/mohana.png";

const data = [
{
src: AyeshaPng,
name: "Ayesha",
description:
"I’m Ayesha from Berhampur, India. I’m a core BWORKZ instructor and the owner of Lissome Dance studio. I have been dancing since the age of 5 and teaching for last 10 years, I’m also a finalist of Jhoom Odisha Jhoom, Dance Odisha Dance & Etv Sampurna reality shows!! Recently I have choreographed many music videos like Dil ko hurt karda, Facebook love , Mo sathee rahi ja. I’m an Odissi & Bharatanatyam dancer too. ",
},
{
src: SmithaPng,
name: "Smitha",
description:
"Hi, I’m Smitha and I’m very excited to be a part of the BWORKZ experts team. Aside from being a Software engineer, music and dance, especially Bollywood, has been a passion since a very early age, but academic and other priorities always took over. BWORKZ came by as the best combo to leverage my dance passion, added to the fitness fun. ",
},
{
src: YogeshPng,
name: "Yogesh",
description:
"Hello Everyone This Is Yogesh Nepali Founder Flyingsteppersstudio at Pune.Like in this dance industry I have been since 10 years. Also Am a instructor in Dab Studio Dubai. Am Graduated BBA From Sinhagad College Of Arts , Commerce & Science.& I have Competed In IIDC IN 2019 PRO AMATUER CATEGORY. And also am a Fitness instructor too Am also an level 0 Dance fitness certified from cult fit health care pvt.ltd. ",
},
{
src: MamthaPng,
name: "Mamtha",
description:
"Do what you LOVE and LOVE what you Do!! Hello All – I am Mamatha Puttaswamy By profession, I am a school teacher. By passion, I am the current Mrs. India Connecticut, a model, a dancer and a sportsperson. Through BWOKZ, I am part of the BWORKZ USA instructor team. I have been with BWORKZ since 2017. I started attending the classes regularly and became an instructor on 27th June 2021. I always enjoyed dancing and fitness and I find both of them in BWORKZ.",
},
{
src: MohanaPng,
name: "Mohana",
description:
"Hello my name is Mohana and I am a BWORKZ specialist and part of the USA core teamsI used to love dancing on Bollywood songs as a child, but never really had an opportunity to explore this area. But BWORKZ helped me wake up my dance passion as well as gave me a way to keep me fit! When rocking on your favorite Bollywood songs, it just takes you to another world! Like they say, dance enables you to find yourself and lose yourself at the same time, and it stands true for me.",
},
];

export default data;

