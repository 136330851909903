import React, { useContext, useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import NavBar from "../Components/NavBar";
import Context from "../Context/Context";
//import DanceAuth from "../Utils/Png/danceAuth.png";
import { useNavigate } from "react-router-dom";
//import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Country from "../Components/Auth/Country";
import "./Login.css";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

const Login = () => {
//const [email, setEmail] = useState("");
const [phoneNumber, setPhoneNumber] = useState("");
//const [password, setPassword] = useState("");
const [err, setErr] = useState("");
const [countryCode, setCountryCode] = useState('91');
const UtilCtx = useContext(Context).util;
const UserCtx = useContext(Context);
//const [passwordVisible, setPasswordVisible] = useState(false);
//const [isPhoneNumberLoginValid, setIsPhoneNumberLoginValid] = useState(true);
const [OTP, setOTP] = useState('')
const [signinResponse, setSigninResponse] = useState(null)
const [otpTimer, setOtpTimer] = useState(null)
//const [showSendOTPButton, setShowSendOTPButton] = useState(false)
//const [otpSent, setOtpSent] = useState(false)

//const passwordVisibilityChange = () => {
//setPasswordVisible((i) => !i);
//};

const Navigate = useNavigate();

useEffect(() => {
    let timerInterval = null;

    if (otpTimer === 0) {
      clearInterval(timerInterval); // Stop the timer when it reaches 0
    } else if (otpTimer > 0) {
      timerInterval = setInterval(() => {
        setOtpTimer(prevTimer => prevTimer - 1); // Decrement the timer every second
      }, 1000);
    }
    return () => clearInterval(timerInterval); // Cleanup function to clear interval on component unmount
  }, [otpTimer]);

const startTimer = () => {
    setOtpTimer(30); // Set the timer to 30 seconds
  }

  const sendOTP = async (event) => {
    event.preventDefault()
    UtilCtx.setLoader(true)

    try {
      const exist = await API.post(
        'user',
        `/any/phone-exists/Bworkz`,
        {
          body: {
            phoneNumber: `+${countryCode}${phoneNumber}`
          }
        }
      )
      if (exist.exists) {
        const response = await Auth.signIn(`+${countryCode}${phoneNumber}`)
        setSigninResponse(response)
        startTimer() // Start the timer when OTP is sent
//        setOtpSent(true)
      } else {
        alert('Sign Up First')
        Navigate('/signup')
      }
    } catch (e) {
      if (e.message === 'Unexpected Lambda Output') {
        alert('Sign Up First')
        Navigate('/signup')
      }
      setErr(e.message)
    } finally {
      UtilCtx.setLoader(false)
    }
  }

  const onSubmit = async (event) => {
      event.preventDefault()
      UtilCtx.setLoader(true)

      try {
        const user = await Auth.sendCustomChallengeAnswer(signinResponse, OTP)
        console.log(await Auth.currentSession())
        if (user) {
          const userdata = await API.get(
            'user',
            `/user/profile/Bworkz`
          )
          UserCtx.setUserData(userdata)
          UserCtx.setIsAuth(true)
          UtilCtx.setLoader(false)
          alert('Logged In')
//          UserCtx.onAuthLoad(true, 'Bworkz')
          Navigate('/dashboard')
        } else {
          setErr(`Incorrect +${countryCode}${phoneNumber}`)
          UtilCtx.setLoader(false)
        }
      } catch (e) {
        setErr(e.message)
      } finally {
        UtilCtx.setLoader(false)
      }
    }
    //resend otp button
//    useEffect(() => {
//      if (otpTimer === 0) {
//        setShowSendOTPButton(true);
//      }
//    }, [otpTimer]);

return (
<div className="w-screen min-h-screen bg-[#f0efef]">
<NavBar />
<div className="flex flex-col items-center mt-12 text-black">
{/*<h3 className="text-[2rem]">FITNESS</h3>*/}
{/*<div className="w-[80%] h-[0.08rem] bg-black flex"></div>*/}
<div className="flex w-[100%] gap-16 justify-center items-end mt-40">
<form className="w-[50vw] max800:w-[90vw]  max-w-[35rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center ">
<h3 className="text-[1.2rem] font-roboto font-bold">
Login
</h3>
<ul className="flex flex-col items-center">
<li className="flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative">
<div className="relative">
<input
className="w-[19.5rem] mr-[1.5rem] border-[2px] px-6 py-2 border-[#9d9d9d78]   rounded-2 max500:w-[80vw]"
type="text"
placeholder="Enter Phone Number"
value={phoneNumber}
onChange={(e) => {
const inputValue = e.target.value;
if (/^\d+$/.test(inputValue)) {
//    setIsPhoneNumberLoginValid(true);
    if(inputValue.length >=0 && inputValue.length <=10 ){
        setPhoneNumber(inputValue);
    }
}
}}
/>
</div>
</li>
{
<select
name="countryCode"
id=""
className="w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 border-[#9d9d9d78]   rounded-2 max500:w-[80vw] mt-6"
onChange={(e) => {
setCountryCode(e.target.value.toString());
console.log(e.target.value.toString());
}}
>
{<Country />}
</select>
}
<li
    className={`flex gap-20 mr-[20px] mt-8  max500:flex-col max500:gap-2 max500:items-start relative`}
  >
    <div className={`relative`}>
      <ValidatorForm>
        <TextValidator
          label={
            <span>
              Enter 6 Digit OTP
            </span>
          }
          className={`w-[19.5rem] mr-[1.5rem] border-[2px] rounded-2 max500:w-[80vw]`}
          variant="outlined"
          inputProps={{ maxLength: 6 }}
          size="small"
          type="text"
          validators={['required']}
          value={OTP}
          onChange={(e) => setOTP(e.target.value)}
          disabled={!signinResponse}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSubmit(e);
            }
          }}
        />
      </ValidatorForm>
    </div>
  </li>
{/*<li className="flex items-center gap-20 mt-6 max500:flex-col max500:gap-2 max500:items-start relative">*/}
{/*<div className="relative">*/}
{/*<input*/}
{/*className="w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 border-[#9d9d9d78]  rounded-2 max500:w-[80vw]"*/}
{/*type={passwordVisible ? "text" : "password"}*/}
{/*placeholder="Enter Your Password" // Add the placeholder here*/}
{/*value={password}*/}
{/*onChange={(e) => setPassword(e.target.value)}*/}
{/*/>*/}

{/*{passwordVisible ? (*/}
{/*<AiFillEye*/}
{/*onClick={(e) => {*/}
{/*e.stopPropagation();*/}
{/*passwordVisibilityChange();*/}
{/*}}*/}
{/*className="absolute top-3 right-8 cursor-pointer"*/}
{/*size={"1.25rem"}*/}
{/*/>*/}
{/*) : (*/}
{/*<AiFillEyeInvisible*/}
{/*onClick={(e) => {*/}
{/*e.stopPropagation();*/}
{/*passwordVisibilityChange();*/}
{/*}}*/}
{/*className="absolute top-3 right-8 cursor-pointer"*/}
{/*size={"1.25rem"}*/}
{/*/>*/}
{/*)}*/}
{/*</div>*/}
{/*</li>*/}
</ul>
{/*<p*/}
{/*className="text-blue-600 text-[0.7rem] mt-6 cursor-pointer"*/}
{/*onClick={() => {*/}
{/*Navigate("/forgotpassword");*/}
{/*}}*/}
{/*>*/}
{/*Forgot Password?{" "}*/}
{/*</p>*/}
{err && <p className="text-[0.8rem] mt-2 text-red-500">{err}</p>}
{ (
    <>
      {(
        <button
          className={`w-[19.5rem] py-2 my-3 ml-2 rounded-2 max500:w-[80vw]`}
          style={{
            backgroundColor: '#FDCF08',
            color: '#ffffff',
            opacity: phoneNumber ? 1 : 0.5
          }}
          onClick={sendOTP}
          disabled={phoneNumber.length !== 10 || (otpTimer !== null && otpTimer > 0)}
        >
          {otpTimer !== null && otpTimer > 0 ? `Resend OTP in ${otpTimer} seconds` : 'Send OTP'}
        </button>
      )}
    </>
  )}
<button
className="p-4 py-1 mt-8 mb-3 bg-[#FDCF08] text-white rounded-lg"
onClick={onSubmit}
>
Login
</button>
<p
className="text-green-600 cursor-pointer"
onClick={() => {
Navigate("/signup");
}}
>
Create a New Account {" "}
</p>
</form>
{/*<img src={DanceAuth} alt="Dance" className="w-[20%] max800:hidden" />*/}
</div>
</div>
</div>
);
};

export default Login;

